import { graphql } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';
import { ContentfulImage, MarkdownCopy, SectionContentProps } from '../types';
import ContentCardCopy from './ContentCardCopy';
import ContentCardPanel from './ContentCardPanel';

export interface ContentCardProps extends SectionContentProps {
  cardImage: ContentfulImage;
  copy: MarkdownCopy;
  imageAlignment: 'left' | 'right';
}

export const contentCardFragment = graphql`
  fragment ContentCardFragment on ContentfulContentCard {
    id
    title
    copy {
      childMarkdownRemark {
        html
      }
    }
    cardImage {
      resolutions(width: 400) {
        src
      }
    }
    imageAlignment
  }
`;

const ContentCard = styled(
  ({
    className,
    copy,
    cardImage: {
      resolutions: { src }
    },
    imageAlignment
  }: ContentCardProps & { className?: string }) => (
    <div className={className}>
      <ContentCardPanel alignment={imageAlignment}>
        <img src={src} />
      </ContentCardPanel>
      <ContentCardPanel
        alignment={imageAlignment === 'left' ? 'right' : 'left'}
      >
        <ContentCardCopy {...copy} />
      </ContentCardPanel>
    </div>
  )
)`
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: auto;
  justify-content: space-between;
  padding: ${props => props.theme.baseMargin}rem;
  border-radius: 0.3rem;
  height: auto;

  margin-bottom: ${props => props.theme.baseMargin}rem;
  > * {
    margin-bottom: ${props => props.theme.baseMargin}rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => breakpoint('md')`
    flex-direction: ${props.imageAlignment !== 'right' ? 'row' : 'row-reverse'};
    flex-wrap: nowrap;
    margin-bottom: ${props.theme.baseMargin}rem;
    padding: ${props.theme.baseMargin}rem;
    > * {
      margin-bottom: 0;
    }
  `};
`;

export default ContentCard;
