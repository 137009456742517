import { Link } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

const NavLink = styled(
  ({
    to,
    className,
    children
  }: {
    to: string;
    className?: string;
    children: React.ReactNode;
    active: boolean;
  }) => (
    <Link to={to} className={className}>
      {children}
    </Link>
  )
)`
  display: block;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  text-decoration: none;
  border-left: 0.2rem solid
    ${props => (props.active ? props.theme.primaryColor : 'transparent')};
  padding-left: 1rem;

  color: ${props =>
    props.active ? props.theme.primaryColor : props.theme.headerColor};

  ${props => breakpoint('lg')`
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      padding-top: 0.2rem;
      padding-bottom: 0.3rem;
      border-left: none;
      border-top: 0.1rem solid ${
        props.active ? props.theme.primaryColor : 'transparent'
      };
    `};
`;

export default NavLink;
