import { graphql } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';
import { Column, Row } from '../Grid';
import { ContentfulImage, SectionContentProps } from '../types';
import OfficeAddress, { AddressProps } from './OfficeAddress';
import OfficeMap from './OfficeMap';

export interface OfficeProps extends SectionContentProps {
  title: string;
  address: AddressProps;
  location: {
    lat: number;
    lon: number;
  };
  mapMarker: ContentfulImage;
}

export const officeFragment = graphql`
  fragment OfficeFragment on ContentfulOffice {
    id
    title
    address {
      ...OfficeAddressFragment
    }
    location {
      lat
      lon
    }
    mapMarker {
      resolutions(width: 50) {
        src
      }
    }
  }
`;

const OfficeAddressWithMargin = styled(OfficeAddress)`
  margin-bottom: ${props => props.theme.baseMargin}rem;

  ${props => breakpoint('md')`
    margin-bottom: 0;
  `};
`;

const Office = styled(
  ({
    title,
    address,
    location: { lat, lon },
    mapMarker,
    className
  }: OfficeProps & { className?: string }) => {
    return (
      <Row className={className}>
        <Column md={6}>
          <h2>{title}</h2>
          <OfficeAddressWithMargin {...address} />
        </Column>
        <Column md={6}>
          <OfficeMap location={{ lat, lng: lon }} mapMarker={mapMarker} />
        </Column>
      </Row>
    );
  }
)`
  margin-bottom: ${props => props.theme.baseMargin}rem;
  h2 {
    font-size: 1.5em;
    margin-top: 0;
  }
`;

export default Office;
