import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

const SendMessageButton = styled.button`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.375rem 1.5rem;
  border-radius: 0.25rem;
  background-color: ${props => props.theme.primaryButtonColor};
  border: 1px solid ${props => props.theme.primaryButtonColor};
  color: ${props => props.theme.color};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  svg {
    margin-left: 0.25rem;
  }
  ${props => breakpoint('md')`
    float: right;
    width: auto;
    `};
`;

export default SendMessageButton;
