import * as React from 'react';
import {
  Col as FlexCol,
  Container as FlexContainer,
  Row as FlexRow
} from 'styled-bootstrap-grid';

export const Row = ({
  children,
  ...rest
}: any & { children: React.ReactNode }) => (
  <FlexRow {...rest}>{children}</FlexRow>
);

export const Column = ({
  children,
  ...rest
}: any & { children: React.ReactNode }) => (
  <FlexCol {...rest}>{children}</FlexCol>
);

export const Container = FlexContainer;
