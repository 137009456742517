import { graphql } from 'gatsby';
import * as React from 'react';
import { ComponentEnhancer, compose, onlyUpdateForKeys } from 'recompose';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';
import { BreakpointNames } from '../../styled-components/types';
import { normalizePath } from '../../utils';
import { Container } from '../Grid';
import { AllSectionProps } from '../types';
import withToggleState, { ToggleStateProps } from '../withToggleState';
import Collapse from './Collapse';
import HamburgerButton from './HamburgerButton';
import Logo from './Logo';
import NavLink from './NavLink';

export interface MainNavbarProps {
  currentPath?: string;
  menuData: AllSectionProps;
}

const FullWidthContainer = styled.div`
  background-color: ${props => props.theme.headerBackgroundColor || 'inherit'};
`;

const Navbar = styled.div`
  position: relative;
  padding-top: ${props => props.theme.baseMargin / 2}rem;
  padding-bottom: ${props => props.theme.baseMargin / 2}rem;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${props => breakpoint(BreakpointNames.lg)`
    flex-flow: row nowrap;
    justify-content: flex-start;
  `};
`;

const Nav = styled.ul`
  margin-left: auto;
  margin-right: 0;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  list-style: none;
  flex-direction: column;

  ${props => breakpoint(BreakpointNames.lg)`
  flex-direction: row;
`};
`;

const NavItem = styled.li``;

export const menuFragment = graphql`
  fragment AllSectionFragment on Query {
    allContentfulSection(
      limit: 1000
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: [sortBy], order: ASC }
    ) {
      edges {
        node {
          id
          title
          pageHeaderTitle
          slug
          sortBy
          hidden
          parentPage {
            id
            title
            slug
          }
        }
      }
    }
  }
`;

const MainNavbar = ({
  active,
  toggle,
  menuData,
  ...props
}: MainNavbarProps & ToggleStateProps) => {
  const sections =
    menuData && menuData.allContentfulSection
      ? menuData.allContentfulSection.edges
          .filter(s => !s.node.hidden && !s.node.parentPage)
          .map(s => ({
            ...s.node,
            path: normalizePath(s.node.slug)
          }))
      : [];

  const currentPath = normalizePath(props.currentPath || '/');

  return (
    <FullWidthContainer>
      <Container>
        <Navbar>
          <Logo to={sections.length ? sections[0].slug : '/'} />
          <HamburgerButton onClick={toggle} aria-label="Main menu" />
          <Collapse isOpen={active}>
            <Nav>
              {sections.map(s => {
                const slug = normalizePath(s.slug);
                const title = s.title;

                return (
                  <NavItem key={slug}>
                    <NavLink
                      to={slug}
                      active={
                        currentPath === slug ||
                        (slug !== '/' && currentPath.startsWith(slug))
                      }
                    >
                      {title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </FullWidthContainer>
  );
};

// Workaround: the gatsby parser explodes if
// you use compose<T,T>(...) syntax, but the syntax
// below works
const enhancer: ComponentEnhancer<
  MainNavbarProps & ToggleStateProps,
  MainNavbarProps
> = compose(
  withToggleState(false),
  onlyUpdateForKeys(['currentPath', 'active'])
);

export default enhancer(MainNavbar);
