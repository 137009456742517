import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

// tslint:disable-next-line:no-submodule-imports
import { Bars } from 'styled-icons/fa-solid/Bars';

const HamburgerButton = styled(
  (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button {...props}>
      <Bars size={16} />
    </button>
  )
)`
  border: 1px solid transparent;
  ${props => breakpoint('lg')`
    display: none;
    `};
  padding: 0.25rem 0.75rem;
  font-size: 1.25em;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.25rem;
  && > svg {
    color: ${props => props.theme.color};
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }
`;

export default HamburgerButton;
