import * as React from 'react';

/** Helper type to represent React components */
export type CompositeComponent<P> =
  | React.StatelessComponent<P>
  | React.ComponentClass<P>;

export interface ToggleStateProps {
  /** Toggleable property */
  active: boolean;
  /** Toggles the "active" property between true and false */
  toggle: () => void;
}

/**
 * Provides a toggleable "active" property to child components like dropdowns
 * @param initialActive Indicates what the initial state of the "active" property should be
 */
export default <TProps extends {}>(initialActive = false) => (
  InnerComponent: CompositeComponent<ToggleStateProps & TProps>
) => {
  return class ToggleStateComponent extends React.Component<
    TProps,
    { active: boolean }
  > {
    constructor(props: TProps) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { active: initialActive };
    }

    public render() {
      const { active } = this.state;

      return (
        <InnerComponent active={active} toggle={this.toggle} {...this.props} />
      );
    }

    private toggle() {
      this.setState(({ active }) => ({
        active: !active
      }));
    }
  };
};
