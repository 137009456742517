import { graphql } from 'gatsby';
import * as React from 'react';
import styled from '../styled-components';
import AlertList from './AlertList';
import CallToAction, { CallToActionContainer } from './CallToAction';
import ContactForm from './ContactForm';
import ContentCard from './ContentCard';
import ContentText from './ContentText';
import { Column, Container, Row } from './Grid';
import HomeTitle from './HomeTitle';
import NewsArticleList from './News/NewsArticleList';
import Office from './Office';
import PageHelmet from './PageHelmet';
import ProductFamilyNav from './ProductFamilyNav';
import SectionTitle from './SectionTitle';
import { AllProductFamilyProps, SectionProps } from './types';

export const sectionFragment = graphql`
  fragment SectionFragment on ContentfulSection {
    id
    title
    pageHeaderTitle
    slug
    showProductFamilySidebar
    metaKeywords {
      metaKeywords
    }
    metaDescription {
      metaDescription
    }
    metaRobots
    parentPage {
      id
      title
      slug
    }
    pageTitle {
      __typename
      ... on ContentfulSectionTitle {
        ...SectionTitleFragment
      }
      ... on ContentfulHomeTitle {
        ...HomeTitleFragment
      }
    }
    contentModules {
      __typename
      ... on ContentfulContactForm {
        ...ContactFormFragment
      }
      ... on ContentfulCallToAction {
        ...CallToActionFragment
      }
      ... on ContentfulOffice {
        ...OfficeFragment
      }
      ... on ContentfulContentCard {
        ...ContentCardFragment
      }
      ... on ContentfulContentText {
        ...ContentTextFragment
      }
      ... on ContentfulNewsArticleList {
        id
        title
      }
    }
  }
`;

const SectionTitleChooser = (props: any) => {
  switch (props.__typename) {
    case 'ContentfulHomeTitle':
      return <HomeTitle {...props} />;
    case 'ContentfulSectionTitle':
      return <SectionTitle {...props} />;
    default:
      return <p>Some other title...</p>;
  }
};

/** Returns a React element based on the type name of the contentful data */
const SectionContentChooser = (props: any) => {
  switch (props.__typename) {
    case 'ContentfulContentCard':
      return <ContentCard {...props} />;
    case 'ContentfulContentText':
      return <ContentText {...props} />;
    case 'ContentfulCallToAction':
      return (
        <CallToActionContainer>
          <CallToAction {...props} />
        </CallToActionContainer>
      );
    case 'ContentfulOffice':
      return <Office {...props} />;
    case 'ContentfulContactForm':
      return <ContactForm {...props} />;
    case 'ContentfulNewsArticleList':
      return <NewsArticleList {...props} />;
    default:
      return <p>Some other layout...</p>;
  }
};

export const SectionRow = styled(Row)`
  padding-left: 0;
  padding-right: 0;
`;

const Section = ({
  contentfulSection: {
    id,
    title,
    showProductFamilySidebar,
    pageTitle,
    contentModules,
    metaDescription,
    metaKeywords,
    metaRobots
  },
  allContentfulProductFamily
}: SectionProps & AllProductFamilyProps) => {
  return (
    <>
      <PageHelmet
        key="helmet"
        metaDescription={metaDescription}
        metaKeywords={metaKeywords}
        metaRobots={metaRobots}
        id={id}
      />
      {pageTitle
        ? pageTitle.map(t => (
            <SectionTitleChooser key={`title-${t.id}`} {...t} />
          ))
        : null}
      <Container>
        <AlertList isHomePage={title === 'Home'} />
        <SectionRow>
          {showProductFamilySidebar ? (
            <Column key="nav-column" lg={3}>
              <ProductFamilyNav
                allContentfulProductFamily={allContentfulProductFamily}
              />
            </Column>
          ) : null}
          <Column key="content-column" lg={showProductFamilySidebar ? 9 : 12}>
            {contentModules
              ? contentModules.map(m => (
                  <SectionContentChooser key={m.id} {...m} />
                ))
              : null}
          </Column>
        </SectionRow>
      </Container>
    </>
  );
};

export default Section;
