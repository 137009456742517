import * as React from 'react';
import Helmet, { HelmetProps } from 'react-helmet';

export interface PageHelmetProps {
  id: string;
  metaDescription?: {
    metaDescription: string;
  } | null;
  metaKeywords?: {
    metaKeywords: string;
  } | null;
  metaTitle?: string | null;
  metaRobots?: string;
}

const PageHelmet = ({
  id,
  metaDescription,
  metaKeywords,
  metaTitle,
  metaRobots,
  ...helmetProps
}: PageHelmetProps & HelmetProps) => (
  <Helmet {...helmetProps}>
    {metaTitle && (
      <meta key={`${id}-og-title`} property="og:title" content={metaTitle} />
    )}
    {metaTitle && (
      <meta key={`${id}-og-title`} property="og:title" content={metaTitle} />
    )}
    {metaDescription ? (
      <meta
        key={`${id}-description`}
        name="description"
        content={metaDescription.metaDescription}
      />
    ) : null}
    {metaDescription ? (
      <meta
        key={`${id}-og-description`}
        property="og:description"
        content={metaDescription.metaDescription}
      />
    ) : null}
    {metaKeywords ? (
      <meta
        key={`${id}-keywords`}
        name="keywords"
        content={metaKeywords.metaKeywords}
      />
    ) : null}
    {metaRobots ? (
      <meta key={`${id}-robots`} name="robots" content={metaRobots} />
    ) : null}
  </Helmet>
);

export default PageHelmet;
