export enum BreakpointNames {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

export type Breakpoints = { [key in BreakpointNames]: number };

export interface FontProps {
  family: string;
  style: string;
  weight: number;
}

export interface LocalFontProps extends FontProps {
  eot: string;
  svg: string;
  ttf: string;
  woff: string;
  woff2: string;
}

export interface SiteThemeVariables {
  baseFontSizePixels: number;
  baseLineHeight: number;
  baseMargin: number;
  bodyFont: FontProps;
  titleFont: FontProps;
  color: string;
  mutedColor: string;
  primaryColor: string;
  secondaryColor: string;
  bodyBackgroundColor: string;
  headerBackgroundColor: string;
  headerColor: string;
  warningColor: string;
  breakpoints: Breakpoints;
  containerWidth: Partial<Breakpoints>;
  localFonts: LocalFontProps[];
}

export interface SiteTheme extends SiteThemeVariables {
  semiTransparentBackgroundColor: string;
  primaryButtonColor: string;
  inputFocusColor: string;
  inputFocusShadowColor: string;
  warningShadowColor: string;
  flexboxgrid: any;
  titleLineHeight: number;
}
