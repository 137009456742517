import * as React from 'react';
import styled from '../styled-components';
import { MarkdownCopy } from './types';

export type MarkdownComponentProps = MarkdownCopy & { className?: string };

const MarkdownComponent = styled(
  ({ childMarkdownRemark: { html }, className }: MarkdownComponentProps) => (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  )
)`
  margin-bottom: ${props => props.theme.baseMargin}rem;

  > * {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.titleFont.family};
  }

  table {
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    td,
    th {
      border: 1px solid ${props => props.theme.secondaryColor};
      margin: 0;
      padding: 10px;
    }

    th {
      color: ${props => props.theme.primaryColor};
      background-color: ${props => props.theme.secondaryColor};
    }
  }

  a {
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
    &.gatsby-resp-image-link {
      display: inline;
    }
  }
  .gatsby-resp-image-wrapper {
    margin-bottom: ${props => props.theme.baseMargin}rem;
    .gatsby-resp-image-background-image {
      border-radius: 0.3rem;
    }
    img {
      border-radius: 0.3rem;
    }
  }
`;

export default MarkdownComponent;
