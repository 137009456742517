import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

const ContentCardPanel = styled(
  ({
    className,
    children
  }: {
    className?: string;
    alignment: string;
    children: React.ReactNode;
  }) => <div className={className}>{children}</div>
)`
  margin-bottom: ${props => props.theme.baseMargin}rem;
  flex-grow: 1;
  flex-shrink: 1;

  > * {
    display: block;
    width: 100%;
    border-radius: 0.3rem;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  ${props => breakpoint('md')`
      width: 45%;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: ${
        props.alignment === 'left' ? `${props.theme.baseMargin}rem` : '0'
      };
      
      > * {
        margin-bottom: 0;
        margin-left: 0
      }
    `};
`;

export default ContentCardPanel;
