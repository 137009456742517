import * as color from 'color';
import { SiteTheme, SiteThemeVariables } from './types';

// Manually specify the font files
import Lato_300_eot from '../fonts/lato-v14-latin-300.eot';
import Lato_300_svg from '../fonts/lato-v14-latin-300.svg';
import Lato_300_ttf from '../fonts/lato-v14-latin-300.ttf';
import Lato_300_woff from '../fonts/lato-v14-latin-300.woff';
import Lato_300_woff2 from '../fonts/lato-v14-latin-300.woff2';

import Exo_400_eot from '../fonts/exo-v6-latin-regular.eot';
import Exo_400_svg from '../fonts/exo-v6-latin-regular.svg';
import Exo_400_ttf from '../fonts/exo-v6-latin-regular.ttf';
import Exo_400_woff from '../fonts/exo-v6-latin-regular.woff';
import Exo_400_woff2 from '../fonts/exo-v6-latin-regular.woff2';

// tslint:disable:object-literal-sort-keys
const themeDefaults: SiteThemeVariables = {
  color: 'white',
  mutedColor: '#d0d0d0',
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  containerWidth: {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140
  },
  baseFontSizePixels: 16, // px
  baseLineHeight: 1.7,
  baseMargin: 1.5,
  bodyBackgroundColor: '#292929',
  headerColor: 'white',
  headerBackgroundColor: 'black',
  primaryColor: '#a1c519',
  secondaryColor: '#45474a',
  warningColor: '#eb8c95',
  bodyFont: {
    family: 'Lato, Arial, Helvetica, sans-serif',
    style: 'normal',
    weight: 300
  },
  titleFont: {
    family: 'Exo, Arial, Helvetica, sans-serif',
    style: 'normal',
    weight: 400
  },
  localFonts: [
    {
      family: 'Lato',
      style: 'normal',
      weight: 300,
      eot: Lato_300_eot,
      svg: Lato_300_svg,
      ttf: Lato_300_ttf,
      woff: Lato_300_woff,
      woff2: Lato_300_woff2
    },
    {
      family: 'Exo',
      style: 'normal',
      weight: 400,
      eot: Exo_400_eot,
      svg: Exo_400_svg,
      ttf: Exo_400_ttf,
      woff: Exo_400_woff,
      woff2: Exo_400_woff2
    }
  ]
};

const createTheme = (themeVars: SiteThemeVariables): SiteTheme => ({
  ...themeVars,
  primaryButtonColor: color(themeVars.primaryColor)
    .darken(0.1)
    .toString(),
  semiTransparentBackgroundColor: color(themeVars.secondaryColor)
    .darken(0.5)
    .alpha(0.6)
    .toString(),
  inputFocusColor: color(themeVars.primaryColor)
    .lighten(0.2)
    .toString(),
  inputFocusShadowColor: color(themeVars.primaryColor)
    .lighten(0.2)
    .alpha(0.25)
    .toString(),
  warningShadowColor: color(themeVars.warningColor)
    .lighten(0.2)
    .alpha(0.25)
    .toString(),
  flexboxgrid: {
    breakpoints: themeVars.breakpoints,
    container: themeVars.containerWidth
  },
  titleLineHeight: themeVars.baseLineHeight * 0.8
});
// tslint:enable:object-literal-sort-keys

export default createTheme(themeDefaults);
