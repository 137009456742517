import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import icon from '../../logo/site_icon_64x64.png';
import { joinUrl } from '../../utils';
import { AllSiteMetadataProps, SiteMetadata } from '../types';

const PUBLIC_URL = process.env.GATSBY_PUBLIC_URL || '/';

interface SiteHelmetProps {
  title: string;
  currentPath?: string;
}

/** Default site metadata in case none has been added in contentful */
const defaultSiteMetadata: SiteMetadata = {
  homeDescription: {
    homeDescription: 'RBV Energy website'
  },
  homeDescription2: {
    homeDescription2:
      'RBV Energy supplies high pressure valves and piping systems to business across the UK, Brazil, Dubai, Norway and the USA. Discover our services online now.'
  },
  homeKeywords: {
    homeKeywords:
      'RBV Energy, high pressure valves, high pressure piping, high pressure piping solutions, high pressure piping system, RBV Energy Ltd'
  },
  siteName: 'RBV Energy'
};

const SiteHelmet = ({ currentPath, title }: SiteHelmetProps) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulSiteMetadata {
          edges {
            node {
              id
              homeDescription {
                homeDescription
              }
              homeDescription2 {
                homeDescription2
              }
              homeKeywords {
                homeKeywords
              }
              siteName
            }
          }
        }
      }
    `}
    // tslint:disable-next-line:jsx-no-lambda
    render={(data: AllSiteMetadataProps) => {
      const siteMetadata =
        data.allContentfulSiteMetadata.edges &&
        data.allContentfulSiteMetadata.edges.length
          ? data.allContentfulSiteMetadata.edges[0].node
          : defaultSiteMetadata;
      return (
        <Helmet>
          <html lang="en" />
          <meta
            key="google-site-verification"
            name="google-site-verification"
            content="k8R97EOmfiycEP57hLQcnM_stF6f--DCbAQpvEOhMz0"
          />
          <meta
            key="home-description"
            name="description"
            content={siteMetadata.homeDescription.homeDescription}
          />
          <meta
            key="home-description-2"
            name="description"
            content={siteMetadata.homeDescription2.homeDescription2}
          />
          <meta
            key="home-keywords"
            name="keywords"
            content={siteMetadata.homeKeywords.homeKeywords}
          />
          <link
            rel="canonical"
            href={joinUrl(PUBLIC_URL, currentPath || '/')}
          />
          <link rel="icon" href={icon} />
          <meta key="locale" property="og:locale" content="en_US" />
          <meta key="type" property="og:type" content="website" />
          <meta
            property="og:url"
            content={joinUrl(PUBLIC_URL, currentPath || '/')}
          />
          <meta property="og:site_name" content={siteMetadata.siteName} />
          <title>{title}</title>
        </Helmet>
      );
    }}
  />
);

export default SiteHelmet;
