import * as React from 'react';
import styled from '../../styled-components';

export type InputProps = React.InputHTMLAttributes<any> & {
  children?: React.ReactNode;
  type: 'text' | 'select' | 'textarea';
  invalid?: boolean;
  className?: string;
};

export default styled(({ type, children, ...rest }: InputProps) => {
  switch (type) {
    case 'select':
      return <select {...rest}>{children}</select>;
    case 'textarea':
      return <textarea {...rest}>{children}</textarea>;
    default:
      return <input {...rest}>{children}</input>;
  }
})`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: ${props => props.theme.bodyFont.family};
  font-size: 1em;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 0;
  line-height: ${props => props.theme.baseLineHeight};
  &:focus {
    outline: 0;
    border-color: ${props =>
      props.invalid ? props.theme.warningColor : props.theme.inputFocusColor};
    box-shadow: 0 0 0 0.2rem
      ${props =>
        props.invalid
          ? props.theme.warningShadowColor
          : props.theme.inputFocusShadowColor};
  }
  &::placeholder {
    color: #6c757d;
  }
`;
