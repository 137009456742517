import { graphql } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../styled-components';
import { Container } from './Grid';
import Breadcrumb from './Layout/Breadcrumb';
import { ContentfulImage, SectionContentProps } from './types';

export interface SectionTitleProps extends SectionContentProps {
  title?: string;
  headline: string;
  backgroundImage?: ContentfulImage;
  maxHeight?: number;
}

export const sectionTitleFragment = graphql`
  fragment SectionTitleFragment on ContentfulSectionTitle {
    id
    title
    headline
    backgroundImage {
      description
      sizes(maxWidth: 800) {
        src
      }
      resolutions(width: 1100, quality: 70) {
        aspectRatio
        height
        src
        width
      }
    }
  }
`;

const SectionTitleContainer = styled(Container)`
  position: relative;
  z-index: 100;
  > *:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitleInner = styled(
  ({
    hasBackgroundImage,
    className,
    children
  }: {
    hasBackgroundImage?: boolean;
    className?: string;
    children: JSX.Element | JSX.Element[];
  }) => <div className={className}>{children}</div>
)`
  background-color: ${({
    hasBackgroundImage,
    theme: { semiTransparentBackgroundColor, secondaryColor }
  }) => (hasBackgroundImage ? semiTransparentBackgroundColor : secondaryColor)};
  border-radius: 0.3rem;
  margin-bottom: ${props => props.theme.baseMargin}rem;
`;

const SectionTitleHeading = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: ${({ theme: { baseMargin } }) => baseMargin}rem;
  padding-bottom: ${({ theme: { baseMargin } }) => baseMargin}rem;
  font-size: ${({ theme: { baseFontSizePixels } }) =>
    baseFontSizePixels * 2.8}px;
`;

const SectionTitle = styled(
  ({
    className,
    headline,
    backgroundImage
  }: SectionTitleProps & { className?: string }) => {
    const imageInfo = backgroundImage?.resolutions;
    return (
      <div className={className}>
        <SectionTitleContainer>
          <SectionTitleInner hasBackgroundImage={!!backgroundImage}>
            <Breadcrumb />
            <SectionTitleHeading>{headline}</SectionTitleHeading>
          </SectionTitleInner>
        </SectionTitleContainer>
        {imageInfo ? <SectionTitleImage src={imageInfo.src} /> : null}
      </div>
    );
  }
)`
  padding-top: ${props => props.theme.baseMargin}rem;
  padding-bottom: ${props =>
    props.backgroundImage ? props.theme.baseMargin : 0}rem;
  overflow: hidden;
  position: relative;
  z-index: 0;
  margin-bottom: ${props => props.theme.baseMargin}rem;
`;

const SectionTitleImage = styled.img`
  left: 0;
  position: absolute;
  bottom: 0;
  width: 1110px;
  z-index: 0;

  display: block;
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
`;

export default SectionTitle;
