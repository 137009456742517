import * as React from 'react';
import Input, { InputProps } from './Input';
import InvalidInputFeedback from './InvalidInputFeedback';

const ContactFormInput = ({
  name,
  error,
  submitted,
  ...props
}: InputProps & {
  name: string;
  error: any;
  submitted: boolean;
}) => {
  const invalid =
    submitted && name && error && typeof props.invalid === 'undefined'
      ? true
      : props.invalid;

  const inputProps: InputProps = {
    ...props,
    id: props.id || name,
    invalid,
    name
  };

  return (
    <>
      <Input key={`input`} {...inputProps} />
      {invalid ? (
        <InvalidInputFeedback key={`input-feedback`}>
          {error || ''}
        </InvalidInputFeedback>
      ) : null}
    </>
  );
};

export default ContactFormInput;
