import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

const Collapse = styled(
  ({
    children,
    className
  }: {
    isOpen: boolean;
    className?: string;
    children: React.ReactNode;
  }) => <div className={className}>{children}</div>
)`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  align-items: center;
  flex-grow: 1;
  flex-basis: 100%;
  ${props => breakpoint('lg')`
      display: flex;
      flex-basis: auto;
  `};
`;

export default Collapse;
