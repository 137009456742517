import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import NewsArticleListItem from './NewsArticleListItem';

const NewsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: ${props => props.theme.baseMargin * -1}rem;
`;

const NewsArticleList = () => {
  const data = useStaticQuery(graphql`
    query NewsArticleListQuery {
      allContentfulNewsArticle(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            ...NewsArticleFragment
          }
        }
      }
    }
  `);

  const articles =
    data.allContentfulNewsArticle?.edges?.map((x: any) => x.node) || [];

  return (
    <NewsListContainer>
      {articles.map((a: any) => (
        <NewsArticleListItem key={a.id} {...a} />
      ))}
    </NewsListContainer>
  );
};

export default NewsArticleList;
