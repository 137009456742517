import { Link } from 'gatsby';
import * as React from 'react';
import logo from '../../logo/rbv-energy-logo.svg';
import styled from '../../styled-components';

const LogoLink = styled(Link)`
  display: block;
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
  background-color: transparent;
  img {
    max-width: 12rem;
    height: 6rem;
    border: 0;
  }
`;

const Logo = ({ to }: { to: string }) => (
  <LogoLink to={to}>
    <img alt="RBV Energy" src={logo} />
  </LogoLink>
);

export default Logo;
