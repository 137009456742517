import { graphql } from 'gatsby';
import * as React from 'react';
import styled from '../styled-components';
import CallToAction, {
  CallToActionContainer,
  CallToActionProps
} from './CallToAction';
import { Column, Container, Row } from './Grid';
import MarkdownComponent from './MarkdownComponent';
import { ContentfulImage, MarkdownCopy, SectionContentProps } from './types';

export interface HomeTitleProps extends SectionContentProps {
  title: string;
  headline: string;
  subheading: MarkdownCopy;
  backgroundImage: ContentfulImage;
  callToAction: CallToActionProps[];
  maxHeight: number;
}

export const homeTitleFragment = graphql`
  fragment HomeTitleFragment on ContentfulHomeTitle {
    id
    title
    headline
    subheading {
      childMarkdownRemark {
        html
      }
    }
    backgroundImage {
      description
      sizes(maxWidth: 800) {
        src
      }
      resolutions(width: 1100, quality: 70) {
        aspectRatio
        height
        src
        width
      }
    }
    callToAction {
      ...CallToActionFragment
    }
  }
`;

const HomeTitleContainer = styled.div`
  margin-bottom: ${props => props.theme.baseMargin}rem;
  overflow: hidden;
  position: relative;
  padding: ${props => props.theme.baseMargin}rem 0;
`;

const HomeTitleRow = styled(Row)`
  position: relative;
  z-index: 100;
`;
const HomeTitleColumn = styled(Column)`
  & > div:last-child {
    margin-bottom: 0;
  }
`;

const HomeTitleHeadline = styled.h1`
  display: block;
  min-width: 50%;
  background-color: ${props => props.theme.semiTransparentBackgroundColor};
  border-radius: 0.3rem;
  z-index: 100;
  margin-top: 0;
  margin-bottom: ${props => props.theme.baseMargin}rem;
  padding: ${props => props.theme.baseMargin}rem;
  font-size: ${props => props.theme.baseFontSizePixels * 2.2}px;
`;

const HomeTitleCopy = styled(MarkdownComponent)`
  display: block;
  min-width: 50%;
  background-color: ${props => props.theme.semiTransparentBackgroundColor};
  border-radius: 0.3rem;
  padding: ${props => props.theme.baseMargin}rem;
  margin-bottom: ${props => props.theme.baseMargin}rem;
  font-size: 1.25em;
  p {
    margin-top: 0;
  }
  z-index: 100;
`;

const TitleImage = styled.img`
  left: 0;
  position: absolute;
  top: 0;
  width: 1110px;
  z-index: 0;

  display: block;
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
`;

const HomeTitle = (props: HomeTitleProps) => {
  const imageInfo = props.backgroundImage
    ? props.backgroundImage.resolutions
    : null;

  return (
    <HomeTitleContainer>
      <Container>
        <HomeTitleRow>
          <HomeTitleColumn lg={6}>
            <HomeTitleHeadline>{props.headline}</HomeTitleHeadline>

            {props.subheading && props.subheading.childMarkdownRemark ? (
              <HomeTitleCopy {...props.subheading} />
            ) : null}
            <CallToActionContainer>
              {props.callToAction
                ? props.callToAction.map(cta => (
                    <CallToAction key={cta.id} {...cta} />
                  ))
                : null}
            </CallToActionContainer>
          </HomeTitleColumn>
        </HomeTitleRow>
      </Container>
      {imageInfo ? (
        <TitleImage
          alt={imageInfo.description || 'Home page image'}
          src={imageInfo.src}
        />
      ) : null}
    </HomeTitleContainer>
  );
};

export default HomeTitle;
