import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import Alert from './Alert';

const AlertListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlertList = ({ isHomePage }: { isHomePage?: boolean }) => {
  const data = useStaticQuery(graphql`
    query AlertListQuery {
      allContentfulAlert {
        edges {
          node {
            id
            title
            copy {
              childMarkdownRemark {
                html
              }
            }
            homePageOnly
            active
          }
        }
      }
    }
  `);

  const alerts = data.allContentfulAlert?.edges?.map((x: any) => x.node) || [];

  const visibleAlerts = alerts.filter(
    (a: any) => a.active && (isHomePage || !a.homePageOnly)
  );

  return visibleAlerts.length ? (
    <AlertListContainer>
      {visibleAlerts.map((a: any) => (
        <Alert key={a.id} {...a} />
      ))}
    </AlertListContainer>
  ) : null;
};

export default AlertList;
