import { graphql, Link, push } from 'gatsby';
import * as React from 'react';
import { mapProps } from 'recompose';
import breakpoint from 'styled-components-breakpoint';
// tslint:disable-next-line:no-submodule-imports
import { AngleDown, AngleRight } from 'styled-icons/fa-solid';
import styled from '../styled-components';
import { joinPath } from '../utils';
import Input from './ContactForm/Input';
import { AllProductFamilyProps, AllProductProps } from './types';

export const allProductFamilyFragment = graphql`
  fragment AllProductFamilyFragment on Query {
    allContentfulProductFamily(
      limit: 1000
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: [sortBy], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          sortBy
          parentSection {
            slug
          }
          collapseToFirstProduct
        }
      }
    }
  }
`;

interface ProductFamilyNavProps {
  productFamilyOptions: {
    label: string;
    value: string;
    collapseToFirstProduct?: boolean;
  }[];
  productOptions: { label: string; value: string }[];
  selectedProductFamily: { label: string; value: string } | null;
  selectedProduct: { label: string; value: string } | null;
}

/** Helper to generate a product family url */
const getProductFamilyUrl = ({
  parentSection,
  slug
}: {
  slug: string;
  parentSection: { slug: string };
}) => joinPath(parentSection.slug, slug);

/** Helper to generate a product url */
const getProductUrl = ({
  slug,
  productFamily
}: {
  slug: string;
  productFamily: { slug: string; parentSection: { slug: string } }[];
}) =>
  joinPath(productFamily[0].parentSection.slug, productFamily[0].slug, slug);

/** Maps the product data into lists of menu items */
const convertProps = (
  props: AllProductFamilyProps &
    Partial<AllProductProps> & {
      contentfulProductFamily?: {
        slug: string;
        title: string;
        parentSection: { slug: string };
        collapseToFirstProduct?: boolean;
      };
      contentfulProduct?: {
        slug: string;
        title: string;
        productFamily: { slug: string; parentSection: { slug: string } }[];
      };
    }
): ProductFamilyNavProps => ({
  productOptions: props.allContentfulProduct
    ? props.allContentfulProduct.edges.map(({ node: product }) => ({
        label: product.title,
        value: getProductUrl(product) || ''
      }))
    : [],

  productFamilyOptions: props.allContentfulProductFamily.edges.map(
    ({ node: productFamily }) => ({
      collapseToFirstProduct: productFamily.collapseToFirstProduct,
      label: productFamily.title,
      value: getProductFamilyUrl(productFamily)
    })
  ),

  selectedProductFamily: props.contentfulProductFamily
    ? {
        label: props.contentfulProductFamily.title,
        value: getProductFamilyUrl(props.contentfulProductFamily)
      }
    : null,

  selectedProduct: props.contentfulProduct
    ? {
        label: props.contentfulProduct.title,
        value: getProductUrl(props.contentfulProduct)
      }
    : null
});

// Navigate to the selected url when the select dropdown changes
const onSelectChange = (e: React.ChangeEvent<any>) =>
  e.target.value && push(e.target.value);

const DropdownPanel = styled.div`
  margin-bottom: ${props => props.theme.baseMargin}rem;
  ${props => breakpoint('lg')`
    display: none;
`};
`;

const NavPanel = styled.ul`
  display: none;
  position: relative;
  margin-bottom: ${props => props.theme.baseMargin}rem;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  width: 100%;
  padding-left: 0;

  ${props => breakpoint('lg')`
  display: block;
`};
`;

const ProductNav = styled.ul`
  padding-left: ${props => props.theme.baseMargin}rem;
`;

const NavItem = styled.li`
  list-style: none;
`;

const NavLink = styled(
  ({
    to,
    className,
    children
  }: {
    to: string;
    className?: string;
    children: React.ReactNode;
    active: boolean;
    indent: boolean;
  }) => (
    <Link to={to} className={className}>
      {children}
    </Link>
  )
)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;

  color: ${props =>
    props.active ? props.theme.primaryColor : props.theme.color};
  svg {
    display: block;
    margin-right: 0.5rem;
  }
  margin-top: 0;
  margin-bottom: ${props => props.theme.baseMargin / 2}rem;
  padding-right: 0;
  padding-left: ${props =>
    props.indent ? `${props.theme.baseMargin / 2}rem` : '0'};

  border-left: 0.1rem solid
    ${props =>
      props.active && props.indent ? props.theme.primaryColor : 'transparent'};
`;

const NavLinkText = styled.div``;

const ProductFamilyNav = ({
  productFamilyOptions,
  productOptions,
  selectedProduct,
  selectedProductFamily
}: ProductFamilyNavProps) => (
  <>
    <DropdownPanel key="dropdown-panel">
      <div>
        <label htmlFor="productFamilySelect">Product family</label>
        <Input
          name="productFamilySelect"
          type="select"
          onChange={onSelectChange}
          placeholder="Select a product family"
          value={selectedProductFamily ? selectedProductFamily.value : ''}
        >
          <option key="(none)" value="" disabled={true}>
            Select a product family...
          </option>
          {productFamilyOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Input>
      </div>
      <div>
        <label htmlFor="productSelect">Product</label>
        <Input
          name="productSelect"
          type="select"
          onChange={onSelectChange}
          disabled={!productOptions || !productOptions.length}
          value={
            selectedProduct
              ? selectedProduct.value
              : selectedProductFamily
              ? selectedProductFamily.value
              : ''
          }
        >
          <option key="(none)" value="" disabled={true} />
          {selectedProductFamily ? (
            <option
              key={selectedProductFamily.value}
              value={selectedProductFamily.value}
            >{`${selectedProductFamily.label} summary`}</option>
          ) : null}

          {productOptions
            ? productOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))
            : null}
        </Input>
      </div>
    </DropdownPanel>
    <NavPanel>
      {productFamilyOptions.map(({ label, value, collapseToFirstProduct }) => (
        <NavItem key={value}>
          <NavLink
            to={value}
            active={
              selectedProductFamily
                ? selectedProductFamily.value === value
                : false
            }
            indent={false}
          >
            {selectedProductFamily && selectedProductFamily.value === value ? (
              <AngleDown key="down-arrow" size={16} />
            ) : (
              <AngleRight key="right-arrow" size={16} />
            )}
            <NavLinkText key="text">{label}</NavLinkText>
          </NavLink>
          {productOptions &&
          !collapseToFirstProduct &&
          selectedProductFamily &&
          selectedProductFamily.value === value ? (
            <ProductNav>
              {productOptions.map(
                ({ label: productLabel, value: productValue }) => (
                  <NavItem key={productValue}>
                    <NavLink
                      active={
                        selectedProduct
                          ? selectedProduct.value === productValue
                          : false
                      }
                      indent={true}
                      to={productValue}
                    >
                      <NavLinkText>{productLabel}</NavLinkText>
                    </NavLink>
                  </NavItem>
                )
              )}
            </ProductNav>
          ) : null}
        </NavItem>
      ))}
    </NavPanel>
  </>
);

export default mapProps(convertProps)(ProductFamilyNav);
