import { InjectedFormikProps } from 'formik';
import { graphql } from 'gatsby';
import * as React from 'react';
import * as Recaptcha from 'react-recaptcha';
// tslint:disable-next-line:no-submodule-imports
import { CaretRight } from 'styled-icons/fa-solid/CaretRight';
import styled from '../../styled-components';
import { Column, Row } from '../Grid';
import MarkdownComponent from '../MarkdownComponent';
import { MarkdownCopy, SectionContentProps } from '../types';
import ContactFormInput from './ContactFormInput';
import InvalidInputFeedback from './InvalidInputFeedback';
import Label from './Label';
import SendMessageButton from './SendMessageButton';

export interface ContactFormProps extends SectionContentProps {
  formName: string;
  formAction: string;
  description: MarkdownCopy;
  conversionInfo: string;
  submitButtonText: string;
}

/** Raw data from the contact form */
export interface ContactFormValues {
  company: string;
  message: string;
  email: string;
  name: string;
  'form-name': string;
  'g-recaptcha-response': string;
  'conversion-info': string;
}

const SITE_RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY || '';

export const contactFormFragment = graphql`
  fragment ContactFormFragment on ContentfulContactForm {
    id
    description {
      childMarkdownRemark {
        html
      }
    }
    formName
    formAction
    submitButtonText
  }
`;

const FormRow = styled(Row)`
  margin-bottom: ${props => props.theme.baseMargin}rem;
`;

const FormPanel = styled.div`
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 0.3rem;
  padding: ${props => props.theme.baseMargin}rem;
  margin-bottom: ${props => props.theme.baseMargin}rem;
  * > :last-child {
    margin-bottom: 0;
  }
`;

/** Contact form component to be wrapped by Formik validation */
const ContactFormCore = (
  props: InjectedFormikProps<
    ContactFormProps & { handleVerifyRecaptcha: (response: string) => void },
    ContactFormValues
  >
) => {
  const {
    description,
    formName,
    formAction,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    setStatus,
    status,
    submitButtonText
  } = props;

  const inputProps = (name: keyof ContactFormValues) => ({
    error: errors[name],
    name,
    onBlur: handleBlur,
    onChange: handleChange,
    submitted: status === 'submitted',
    value: values[name]
  });

  if (!SITE_RECAPTCHA_KEY) {
    // tslint:disable-next-line:no-console
    console.log(
      'No recaptcha key found.  Site key should be saved in environment variable GATSBY_SITE_RECAPTCHA_KEY'
    );
  }

  const handleVerifyRecaptcha = (response: string) =>
    setFieldValue('g-recaptcha-response', response);

  const handleRecaptchaExpired = () =>
    setFieldValue('g-recaptcha-response', '');

  const handleSubmitWrapper = (e: React.FormEvent<any>) => {
    // Set the form status to submitted, so we can only show errors
    // once the user has clicked submit
    setStatus('submitted');
    return handleSubmit(e);
  };

  return (
    <FormPanel>
      <MarkdownComponent {...description} />
      <form
        name={formName}
        method="post"
        action={formAction}
        data-netlify={true}
        data-netlify-recaptcha={!!SITE_RECAPTCHA_KEY}
        onSubmit={handleSubmitWrapper}
      >
        <FormRow>
          <Column sm={2}>
            <Label htmlFor="name">Name</Label>
          </Column>
          <Column sm={10}>
            <ContactFormInput
              type="text"
              {...inputProps('name')}
              placeholder="Your name"
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column sm={2}>
            <Label htmlFor="email">Email</Label>
          </Column>
          <Column sm={10}>
            <ContactFormInput
              type="text"
              placeholder="you@example.com"
              {...inputProps('email')}
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column sm={2}>
            <Label htmlFor="company">Company</Label>
          </Column>
          <Column sm={10}>
            <ContactFormInput
              type="text"
              placeholder="Your company name"
              {...inputProps('company')}
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column sm={2}>
            <Label htmlFor="message">Message</Label>
          </Column>
          <Column sm={10}>
            <ContactFormInput type="textarea" {...inputProps('message')} />
          </Column>
        </FormRow>
        {!!SITE_RECAPTCHA_KEY ? (
          <FormRow>
            <Column sm={10} smOffset={2}>
              <Recaptcha
                sitekey={SITE_RECAPTCHA_KEY}
                verifyCallback={handleVerifyRecaptcha}
                expiredCallback={handleRecaptchaExpired}
              />
              {status === 'submitted' &&
              errors &&
              errors['g-recaptcha-response'] ? (
                <InvalidInputFeedback>
                  {errors['g-recaptcha-response']}
                </InvalidInputFeedback>
              ) : null}
            </Column>
          </FormRow>
        ) : null}
        <input
          type="hidden"
          name="conversion-info"
          value={values['conversion-info']}
        />
        <FormRow>
          <Column sm={10} smOffset={2}>
            <SendMessageButton type="submit">
              {submitButtonText || 'Send message'}
              <CaretRight size={16} />
            </SendMessageButton>
          </Column>
        </FormRow>
      </form>
    </FormPanel>
  );
};

export default ContactFormCore;
