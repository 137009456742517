import { Link } from 'gatsby';
import * as React from 'react';
import styled from '../../styled-components';
import { joinPath } from '../../utils';

interface BreadcrumbProps {
  breadcrumbPath?: { slug: string; title: string }[];
}

interface BreadcrumbPropsWithPath {
  breadcrumbPath: { slug: string; title: string; path: string }[];
}

const mapBreadcrumbProps = ({
  breadcrumbPath
}: BreadcrumbProps): BreadcrumbPropsWithPath => ({
  breadcrumbPath: (breadcrumbPath || []).reduce(
    (
      results: { title: string; path: string; slug: string }[],
      { title, slug },
      i
    ) => {
      const path = i ? joinPath(results[i - 1].path, slug) : slug;
      return [...results, { title, path, slug }];
    },
    []
  )
});

const BreadcrumbContainer = styled.ol`
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.theme.baseMargin / 4}rem
    ${props => props.theme.baseMargin}rem;
  list-style: none;
  margin-bottom: ${props => props.theme.baseMargin}rem;
  border-radius: 0.25rem;
`;

const BreadcrumbItem = styled(
  ({
    children,
    active,
    first,
    last,
    ...props
  }: {
    active: boolean;
    first: boolean;
    last: boolean;
    children: React.ReactNode;
  } & React.LiHTMLAttributes<any>) => (
    <li
      property={last ? undefined : 'itemListElement'}
      typeof={last ? undefined : 'ListItem'}
      {...props}
    >
      {children}
    </li>
  )
)`
  color: ${props => props.theme.mutedColor};
  padding-left: ${props => (props.first ? '0' : '0.5rem')};
  &::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: ${props => props.theme.mutedColor};
    content: ${props => (props.first ? '' : '"/"')};
  }
`;

const Breadcrumb = () => {
  const breadcrumbs = React.useContext(BreadcrumbContext);
  const { breadcrumbPath } = mapBreadcrumbProps({
    breadcrumbPath: breadcrumbs
  });

  return (
    <BreadcrumbContainer vocab="http://schema.org/" typeof="BreadcrumbList">
      {breadcrumbPath
        ? breadcrumbPath.map(({ path, title }, i) => (
            <BreadcrumbItem
              key={path}
              first={i === 0}
              last={i === breadcrumbPath.length - 1}
              active={i === breadcrumbPath.length - 1}
            >
              {i < breadcrumbPath.length - 1 ? (
                <>
                  <Link property="item" typeof="Thing" to={path}>
                    <span property="name">{title}</span>
                  </Link>
                  <meta property="position" content={`${i + 1}`} />
                </>
              ) : (
                <span>{title}</span>
              )}
            </BreadcrumbItem>
          ))
        : null}
    </BreadcrumbContainer>
  );
};

export const BreadcrumbContext = React.createContext<
  { slug: string; title: string }[]
>([]);

export default Breadcrumb;
