import { createGlobalStyle } from './index';
import { LocalFontProps } from './types';

/** Generates css for locally hosted fonts */
const createLocalFonts = (allFontProps: LocalFontProps[]) =>
  allFontProps.reduce(
    (accumulator: string, fontProps) => `${accumulator}
    @font-face {
        font-family: '${fontProps.family}';
        font-style: ${fontProps.style};
        font-weight: ${fontProps.weight};
        src: url(${fontProps.eot}); /* IE9 Compat Modes */
        src: /* url(${fontProps.eot}?#iefix) format('embedded-opentype'),*/
          /* IE6-IE8 */ url(${fontProps.woff2}) format('woff2'),
          /* Super Modern Browsers */ url(${fontProps.woff})
            format('woff'),
          /* Modern Browsers */ url(${fontProps.ttf})
            format('truetype'),
          /* Safari, Android, iOS */ url(${fontProps.svg}#${fontProps.family})
            format('svg'); /* Legacy iOS */
      }

      `,
    ''
  );

/** Themed global style component */
const GlobalStyle = createGlobalStyle`

  // Inject local fonts
  ${props => createLocalFonts(props.theme.localFonts)}

  @-ms-viewport {
    width: device-width;
  }

  *,
  *::before,
  *::after,
  *:after,
  *:before {
    -webkit-box-sizing: inherit;
    box-sizing: border-box;
  }
    
  html {
    // Always show the scrollbar so it doesn't
    // appear and disappear
    overflow-y: scroll;
    position: relative;
    min-height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    font-size: ${props => props.theme.baseFontSizePixels}px;
  }
  
  body {
    // Overrides the styles set by bootstrap modals, which
    // try to compensate for the scrollbar but end up messing
    // up the padding
    padding-right: 0 !important;
    
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 100px;

    background-color: ${props => props.theme.bodyBackgroundColor};
    color: ${props => props.theme.color};
    font-family: ${props => props.theme.bodyFont.family};
    line-height: ${props => props.theme.baseLineHeight};
    font-weight: ${props => props.theme.bodyFont.weight};
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${props => props.theme.titleFont.weight};
      color: ${props => props.theme.primaryColor};
      font-family: ${props => props.theme.titleFont.family};
      line-height: ${props => props.theme.titleLineHeight};
      margin-bottom: ${props => props.theme.baseMargin}rem;
      margin-top: 0;
    }

    p, ul, ol {
      margin-top: 0;
      margin-bottom: ${props => props.theme.baseMargin}rem;
    }

    a {
      color: ${props => props.theme.primaryColor};
      text-decoration: none;
    }
  }

`;

export default GlobalStyle;
