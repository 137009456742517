import * as color from 'color';
import * as React from 'react';
import styled from '../../styled-components';
import MarkdownComponent from '../MarkdownComponent';
import { MarkdownCopy } from '../types';

const AlertWrapper = styled.div`
  border: 2px solid
    ${props =>
      color(props.theme.primaryColor)
        .darken(0.2)
        .desaturate(0.7)
        .toString()};
  border-radius: 0.3rem;
  background-color: ${props =>
    color(props.theme.primaryColor)
      .darken(0.5)
      .desaturate(0.7)
      .toString()};

  padding-left: ${props => props.theme.baseMargin}rem;
  padding-top: ${props => props.theme.baseMargin / 2}rem;
  padding-bottom: ${props => props.theme.baseMargin / 2}rem;
  padding-right: ${props => props.theme.baseMargin}rem;
  margin-bottom: ${props => props.theme.baseMargin}rem;

  h1,
  h2,
  h3 {
    color: ${props =>
      color(props.theme.primaryColor)
        .desaturate(0.2)
        .toString()};
    margin-bottom: ${props => props.theme.baseMargin / 2}rem;
  }

  p {
    margin-bottom: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

const Alert = ({ title, copy }: { title: string; copy?: MarkdownCopy }) => (
  <AlertWrapper>
    <h2>{title}</h2>
    {copy ? (
      <MarkdownComponent childMarkdownRemark={copy.childMarkdownRemark} />
    ) : null}
  </AlertWrapper>
);

export default Alert;
