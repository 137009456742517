import styled from '../../styled-components';

const Label = styled.label`
  display: block;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0;
  padding-right: 0.75rem;
  line-height: ${props => props.theme.baseLineHeight};
  border: 1px solid transparent;
`;

export default Label;
