import * as React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  WithGoogleMapProps,
  withScriptjs,
  WithScriptjsProps
} from 'react-google-maps';
import { compose } from 'recompose';
import { ContentfulImage } from '../types';

const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY;

interface OfficeMapProps {
  location: { lat: number; lng: number };
  mapMarker: ContentfulImage;
}

const OfficeMapCore = compose<
  any,
  WithScriptjsProps & WithGoogleMapProps & OfficeMapProps
>(
  withScriptjs,
  withGoogleMap
)((props: WithGoogleMapProps & WithScriptjsProps & OfficeMapProps) => (
  <GoogleMap defaultZoom={10} defaultCenter={props.location}>
    <Marker
      position={props.location}
      icon={props.mapMarker ? props.mapMarker.resolutions.src : undefined}
    />
  </GoogleMap>
));

const OfficeMap = (props: OfficeMapProps) => (
  <OfficeMapCore
    {...props}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ width: '100%', height: 300 }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

export default OfficeMap;
