import { Link } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';
import { Container } from '../Grid';

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  margin-top: ${props => props.theme.baseMargin * 2}rem;
  background-color: ${props => props.theme.headerBackgroundColor};
  color: ${props => props.theme.mutedColor};

  ${props => breakpoint('md')`
      height: 60px;
`};
`;

const FooterText = styled.p`
  margin-bottom: 0;
  font-size: 90%;
  font-weight: 300;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: ${props => props.theme.baseMargin}rem;
  line-height: ${props => props.theme.baseLineHeight};
  overflow: hidden;

  ${props => breakpoint('md')`
      padding-top: ${props.theme.baseMargin}rem;
`};
`;

const SiteFooter = () => (
  <Footer>
    <Container>
      <FooterText>
        Copyright 2018 <Link to="/">RBV Energy</Link> | All rights reserved |
        Created by Andrew Bain
      </FooterText>
    </Container>
  </Footer>
);

export default SiteFooter;
