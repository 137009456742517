import { graphql, Link } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
// tslint:disable-next-line:no-submodule-imports
import { CaretRight } from 'styled-icons/fa-solid/CaretRight';
import styled from '../styled-components';
import { normalizePath } from '../utils';
import { SectionContentProps } from './types';

export interface CallToActionProps extends SectionContentProps {
  buttonText: string;
  navigateToSlug: string;
  primaryOrSecondary?: 'primary' | 'secondary';
  showArrow?: boolean;
}

export const callToActionFragment = graphql`
  fragment CallToActionFragment on ContentfulCallToAction {
    id
    buttonText
    navigateToSlug
    primaryOrSecondary
  }
`;

const IconWithMargin = styled(CaretRight)`
  margin-left: 0.25rem;
`;

export const CallToActionContainer = styled(
  ({
    children,
    className
  }: {
    children: React.ReactNode;
    className?: string;
  }) => <div className={className}>{children}</div>
)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.baseMargin}rem;

  > * {
    margin-bottom: ${props => props.theme.baseMargin}rem;
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => breakpoint('md')`
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: ${props.theme.baseMargin}rem;
  
  > * {
    max-width: calc(50% - ${props.theme.baseMargin * 0.5}rem);
    margin-bottom: 0 !important;
      
      margin-left: ${props.theme.baseMargin}rem;
      &:first-child {
         margin-left: 0;
      }
    }


  `};
`;

const CallToAction = styled(
  ({
    buttonText,
    navigateToSlug,
    className,
    primaryOrSecondary,
    showArrow
  }: CallToActionProps & { className?: string }) => {
    const primary = primaryOrSecondary !== 'secondary';
    return (
      <Link to={normalizePath(navigateToSlug)} className={className}>
        {buttonText}
        {primary || showArrow ? <IconWithMargin size="16" /> : null}
      </Link>
    );
  }
)`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: ${props => props.theme.baseMargin / 4}rem
    ${props => props.theme.baseMargin}rem;
  border-radius: 0.25rem;
  width: auto;
  background-color: ${props =>
    props.primaryOrSecondary !== 'secondary'
      ? props.theme.primaryButtonColor || props.theme.primaryColor
      : props.theme.semiTransparentBackgroundColor};
  color: ${props => props.theme.color};
  margin-bottom: ${props => props.theme.baseMargin}rem;
`;

export default CallToAction;
