import { FormikBag, InjectedFormikProps, withFormik } from 'formik';
import { push } from 'gatsby';
import { compose } from 'recompose';
import withConversionInfo from '../../withConversionInfo';
import ContactFormCore, {
  ContactFormProps,
  ContactFormValues
} from './ContactFormCore';

/** Converts the form values into form data object which can be posted to netlify */
const serializeFormData = (values: ContactFormValues) =>
  Object.entries(values).reduce((formData: FormData, [key, value]) => {
    formData.append(key, value);
    return formData;
  }, new FormData());

/** Wrap the contact form with Formik validation */
export default compose<
  InjectedFormikProps<ContactFormProps, ContactFormValues>,
  ContactFormProps
>(
  withConversionInfo,
  withFormik<ContactFormProps, ContactFormValues>({
    displayName: 'ContactForm',
    handleSubmit: (
      payload: ContactFormValues,
      { props: { formAction } }: FormikBag<ContactFormProps, ContactFormValues>
    ) => {
      const formData = serializeFormData(payload);

      const request = new XMLHttpRequest();
      request.open('POST', formAction);
      request.send(formData);

      push(formAction);
    },
    mapPropsToValues: ({ conversionInfo, formName }: ContactFormProps) => ({
      company: '',
      'conversion-info': conversionInfo,
      email: '',
      'form-name': formName,
      'g-recaptcha-response': '',
      message: '',
      name: ''
    }),
    validate: (values: ContactFormValues, _: ContactFormProps) => {
      const errors: any = {};

      if (!values.email) {
        errors.email = 'Please enter your email address';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email =
          'Please enter an email address in the format you@example.com';
      }

      if (!values.name) {
        errors.name = 'Please enter your name';
      }

      if (!values.company) {
        errors.company = 'Please enter your company name';
      }

      if (!values.message) {
        errors.message = 'Please type a message to send';
      }

      if (!values['g-recaptcha-response']) {
        errors['g-recaptcha-response'] =
          'Please complete the Recaptcha challenge';
      }

      return errors;
    },
    validateOnBlur: true,
    validateOnChange: true
  })
)(ContactFormCore);
