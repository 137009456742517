/** Removes all leading and trailing slashes from a path */
export const cleanPath = (path: string) =>
  (path || '').replace(/^\/+|\/+$/g, '');

/** Makes sure a path has a single leading and trailing slash */
export const normalizePath = (path: string) => {
  const result = cleanPath(path);
  return result && result !== '/' ? `/${result}/` : '/';
};

/** Joins path sections, accounting for leading and trailing slashes */
export const joinPath = (...paths: string[]) => {
  const result = paths.map(x => cleanPath(x)).join('/');
  return result && result !== '/' ? `/${result}/` : '/';
};

export const joinUrl = (baseUrl: string, ...paths: string[]) => {
  return (baseUrl || '').replace(/\/+$/g, '') + joinPath(...paths);
};
