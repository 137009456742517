import { graphql } from 'gatsby';
import * as React from 'react';
import styled from '../../styled-components';
import withConversionInfo, {
  ConversionInfoProps
} from '../../withConversionInfo';

export interface AddressProps {
  id: string;
  name: string;
  addressCountry?: string;
  addressLocality?: string;
  postOfficeBoxNumber?: string;
  postalCode?: string;
  streetAddress?: string;
  telephone?: string;
  telephoneUrl?: string;
  email?: string;
  vatId?: string;
  addressRegion?: string;
}

export const officeAddressFragment = graphql`
  fragment OfficeAddressFragment on ContentfulAddress {
    id
    name
    addressCountry
    addressLocality
    postOfficeBoxNumber
    postalCode
    streetAddress
    telephone
    telephoneUrl
    email
    vatId
  }
`;

const AddressLabel = styled.span`
  color: ${props => props.theme.primaryColor};
`;

const AddressPart = ({
  itemProp,
  value,
  label
}: {
  itemProp: string;
  value?: string;
  label?: string;
}) =>
  value ? (
    <>
      {label ? <AddressLabel key="label">{label}</AddressLabel> : null}
      <span itemProp={itemProp} key="value">
        {value}
      </span>
      <br key="br" />
    </>
  ) : null;

/** Adds Google Ads conversion info to the subject of an email link */
const getEmailUrl = (email: string, conversionInfo: string) => {
  let result = `mailto://${encodeURIComponent(email)}?subject=`;

  let subject = encodeURIComponent('RBV Energy website enquiry');
  if (conversionInfo && conversionInfo !== 'Undefined') {
    subject += encodeURIComponent(` - source: ${conversionInfo}`);
  }

  result += subject;

  return result;
};

const OfficeAddress = ({
  className,
  name,
  addressCountry,
  addressLocality,
  addressRegion,
  conversionInfo,
  postOfficeBoxNumber,
  postalCode,
  streetAddress,
  telephone,
  telephoneUrl,
  email,
  vatId
}: AddressProps & ConversionInfoProps & { className?: string }) => {
  return (
    <div
      className={className}
      itemScope={true}
      itemType="http://schema.org/Organization"
    >
      <AddressPart itemProp="name" value={name} />
      <div
        itemProp="address"
        itemScope={true}
        itemType="http://schema.org/PostalAddress"
      >
        <AddressPart
          key="streetAddress"
          itemProp="streetAddress"
          value={streetAddress}
        />
        <AddressPart
          key="addressLocality"
          itemProp="addressLocality"
          value={addressLocality}
        />
        <AddressPart
          key="addressRegion"
          itemProp="addressRegion"
          value={addressRegion}
        />
        <AddressPart
          key="postalCode"
          itemProp="postalCode"
          value={postalCode}
        />
        <AddressPart
          key="postOfficeBoxNumber"
          itemProp="postOfficeBoxNumber"
          value={postOfficeBoxNumber}
        />
        <AddressPart
          key="addressCountry"
          itemProp="addressCountry"
          value={addressCountry}
        />
      </div>
      {telephone ? (
        <>
          <AddressLabel>Telephone: </AddressLabel>
          <a
            href={`tel:${telephoneUrl || telephone}`}
            className="office-telephone"
            itemProp="telephone"
          >
            {telephone}
          </a>
          <br />
        </>
      ) : null}
      {email ? (
        <>
          <AddressLabel>Email: </AddressLabel>
          <a href={getEmailUrl(email, conversionInfo)} itemProp="email">
            {email}
          </a>
          <br />
        </>
      ) : null}
      <AddressPart
        label="VAT Reg No.:"
        key="vatID"
        itemProp="vatID"
        value={vatId}
      />
    </div>
  );
};

export default withConversionInfo(OfficeAddress);
