import { graphql } from 'gatsby';
import * as React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from '../styled-components';
import MarkdownComponent from './MarkdownComponent';
import { MarkdownCopy, SectionContentProps } from './types';

export interface ContentTextProps extends SectionContentProps {
  title: string;
  copy: MarkdownCopy;
}

export const contentTextFragment = graphql`
  fragment ContentTextFragment on ContentfulContentText {
    id
    copy {
      childMarkdownRemark {
        html
      }
    }
  }
`;

const ContentText = styled(
  ({ copy, className }: ContentTextProps & { className: string }) => {
    return <MarkdownComponent {...copy} className={className} />;
  }
)`
  ${props => breakpoint('md')`
margin-bottom: ${props.theme.baseMargin}rem;
width: 70%;
`};
`;

export default ContentText;
