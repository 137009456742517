import * as styledComponents from 'styled-components';
import { SiteTheme } from './types';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  SiteTheme
>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
