import * as React from 'react';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider } from '../../styled-components';
import GlobalStyle from '../../styled-components/GlobalStyle';
import theme from '../../styled-components/theme';
import { normalizePath } from '../../utils';
import MainNavbar from '../MainNavbar';
import { BreadcrumbContext } from './Breadcrumb';
import SiteFooter from './SiteFooter';
import SiteHelmet from './SiteHelmet';

interface LayoutProps {
  title: string;
  currentSectionPath?: string;
  currentLocation?: string;
  breadcrumbPath?: { slug: string; title: string }[];
  menuData: any;
  children: any;
}

const gridTheme = {
  col: {
    padding: theme.baseFontSizePixels * theme.baseMargin * 0.5
  },
  container: {
    padding: theme.baseFontSizePixels * theme.baseMargin
  },
  row: {
    padding: theme.baseFontSizePixels * theme.baseMargin * 0.5
  }
};

const Layout = ({
  children,
  menuData,
  currentSectionPath,
  currentLocation,
  breadcrumbPath,
  title
}: LayoutProps) => {
  const currentPath = normalizePath(currentLocation || '/');
  return (
    <>
      <SiteHelmet title={title} currentPath={currentPath} />
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <BreadcrumbContext.Provider value={breadcrumbPath || []}>
            <GlobalStyle />
            <MainNavbar
              key="navbar"
              menuData={menuData}
              currentPath={normalizePath(currentSectionPath || '/')}
            />
            {children}
            <SiteFooter />
          </BreadcrumbContext.Provider>
        </GridThemeProvider>
      </ThemeProvider>
    </>
  );
};

export default Layout;
