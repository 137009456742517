import autobind from 'class-autobind';
import * as React from 'react';
import { CompositeComponent } from './components/withToggleState';

export interface ConversionInfoProps {
  conversionInfo: string;
}

const withConversionInfo = <TProps extends {}>(
  InnerComponent: CompositeComponent<ConversionInfoProps & TProps>
) => {
  return class ToggleStateComponent extends React.Component<
    TProps,
    Partial<ConversionInfoProps>
    > {
    constructor(props: TProps) {
      super(props);
      autobind(this);

      this.state = { conversionInfo: this.getConversionInfo() || undefined };
    }

    public render() {
      const conversionInfo = this.state.conversionInfo || '';
      return <InnerComponent conversionInfo={conversionInfo} {...this.props} />;
    }

    public componentDidMount() {
      if (typeof this.state.conversionInfo === 'undefined') {
        this.setState({ conversionInfo: this.getConversionInfo() || '' });
      }
    }

    private getCookie(name: string) {
      if (typeof window !== 'undefined' && (window as any).document) {
        const nameEQ = name + '=';
        const ca = (window as any).document.cookie.split(';');
        for (let c of ca) {
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
        }
      }
      return null;
    }

    private parseCookie() {
      const values: { [key: string]: string } = {};
      const cookie = this.getCookie('__utmz');
      if (cookie) {
        const z = cookie.split('.');
        if (z.length >= 4) {
          const y = z[4].split('|');
          for (const x of y) {
            const pair = x.split('=');
            values[pair[0]] = pair[1];
          }
        }
      }
      return values;
    }

    private getConversionInfo() {
      const gaCookie = this.parseCookie();

      if (gaCookie.utmcmd === 'organic' && gaCookie.utmcsr === 'google') {
        return 'Google Organic';
      }
      if (gaCookie.utmcmd === 'organic' && gaCookie.utmcsr === 'bing') {
        return 'Bing Organic';
      }
      if (gaCookie.utmgclid) {
        return `Google Adwords - ${gaCookie.utmgclid}`;
      }
      if (gaCookie.utmdclid) {
        return `DoubleClick - ${gaCookie.utmdclid}`;
      }
      if (gaCookie.utmcsr === '(direct)') {
        return 'Direct';
      }
      return gaCookie.utmcmd + ' - ' + gaCookie.utmcsr;
    }
  };
};

export default withConversionInfo;
